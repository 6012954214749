.SideNav {
    width: 150px;
    text-align: left;
    color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
}

.SideNav-menubutton {
    padding: 20px 15px;
    display: none;
}

.SideNav-content {
    border-right: 1px solid rgba(0,0,0,0.1);
    padding-top: 5px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    box-sizing: border-box;
    position: sticky;
    top: 0;
}

.SideNav-content-close {
    display: none;
    padding: 10px 10px;
}

.SideNav-content-close svg {
    padding: 2px 5px;
    border: 1px solid #4ca9e0;
}

.SideNav-logo {
    text-align: center;
}

.SideNav-logo img {
    width: 70px;
}

.SideNav-items {
    flex-grow: 1;
    flex-shrink: 0;
}

.SideNav-item {
    position: relative;
    padding: 20px 10px;
    display: block;
    color: #fffa;
    text-decoration: none;
    text-align: center;
    transition: color ease 0.3s;
}

.SideNav-item:hover {
    color: #96961f;
}

.SideNav-item-icon {
    font-size: 40px;
}

.SideNav-item-label {
    margin-top: 5px;
    font-size: 12px;
}

.SideNav-user {
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.SideNav-user-image {
    flex-grow: 0;
    flex-shrink: 0;
    text-decoration: none;
    color: inherit;
}

.SideNav-user-name {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
    text-decoration: none;
    color: inherit;
}

.SideNav-user-actions {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 5px;
}

.SideNav-user-action {
    cursor: pointer;
}

.SideNav-copyright {
    padding: 3px 10px;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 12px;
    color: #ccc;
}

.SideNav-version {
    padding: 3px 10px;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 12px;
    color: #ccc;
}

@media (max-width: 800px) {
    .SideNav {
        width: 30px;
        padding-top: 0;
    }
   
    .SideNav-menubutton {
        display: block;
    }

    .SideNav-content-close {
        display: block;
    }

    .SideNav-content {
        display: none;
        position: fixed;
        background-color: #fff;
        box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
        height: 100vh;
        max-width: 150px;
        z-index: 1;
    }

    .SideNav-content.expanded {
        display: block;
    }
}