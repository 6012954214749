.Spinner {
    display: block;
    position: relative;
    width: 40px;
    height: 40px;
}

.Spinner div {
    position: absolute;
    border: 4px solid #064B37;
    opacity: 1;
    border-radius: 50%;
    animation: Spinner 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.Spinner.color-white div {
    border-color: #fff;
}

.Spinner div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes Spinner {
    0% {
        top: 16px;
        left: 16px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 16px;
        left: 16px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 16px;
        left: 16px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 32px;
        height: 32px;
        opacity: 0;
    }
}
  