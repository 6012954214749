.LabelValue {
    padding: 10px 20px;
    border: 1px solid #666;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
}

.LabelValue:not(:last-of-type) {
    border-bottom: 0;
}

.LabelValue-label {
    flex-grow: 0;
    flex-shrink: 0;
    color: #777;
    padding-right: 20px;
}

.LabelValue-value {
    flex-basis: 100px;
    flex-grow: 1;
    flex-shrink: 1;
    text-align: right;
}
