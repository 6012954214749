body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  --color-primary: #675999;  
  --color-error: #f44f3b;
  --color-error-light: #fec5b6;
  --color-warning: #b3b34f;
  --color-warning-light: #fff5cb;
  --color-ok: #4fb391;
  --color-ok-light: #83fffe;
}
