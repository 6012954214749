.App-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-height: calc(100vh - 50px);
  width: 100%;
  background: #556266;
}

.App-content .SideNav {
  flex-grow: 0;
  flex-shrink: 0;
}

.App-content-main {
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 1;
  text-align: left;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.App-content-main a {
  text-decoration: none;
  color: #333;
}
