.PageContent .PageContent-inner {
    margin-left: calc(max((100% - 1300px) / 2, 10px));
    width: 900px;
    max-width: calc(100vw - 20px);
    min-height: 100vh;
}

.PageContent.small .PageContent-inner {
    width: 400px;
}

.PageContent.full .PageContent-inner {
    width: 1300px;
}

.PageContent.centered .PageContent-inner {
    text-align: center;
}
