
.DashboardPage-hosts {
    display: grid;
    grid-template-columns: 350px 350px 350px;
    grid-gap: 30px;
    justify-content: space-between;
}

.DashboardPage-host {
    max-width: 350px;
    border-radius: 10px;
    box-shadow: 3px 3px 10px rgba(0,0,0,0.1);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    color: #333;
    font-size: 15px;
    position: relative;
    border: 1px solid #eee;
    cursor: pointer;
    background: #fff;
    border: none;
}

.DashboardPage-host.status-ok {
    background: var(--color-ok);
}

.DashboardPage-host.status-warning {
    background: var(--color-warning);
}

.DashboardPage-host.status-critical {
    background: var(--color-error);
}

.DashboardPage-host-status {
    flex-grow: 0;
    flex-shrink: 0;
    width: 70px;
    align-self: stretch;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    display: flex;
    letter-spacing: 1px;
}

.DashboardPage-host-status > div {
    align-self: center;
    text-align: center;
    flex-grow: 1;
    color: #333;
    font-weight: bold;
}

.DashboardPage-host.status-ok .DashboardPage-host-status > div {
    color: var(--color-ok-light);
}

.DashboardPage-host.status-warning .DashboardPage-host-status > div {
    color: var(--color-warning-light);
}

.DashboardPage-host.status-critical .DashboardPage-host-status > div {
    color: var(--color-error-light);
}

.DashboardPage-host-details {
    border-left: 1px solid #333;
    flex-grow: 1;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 70px;
    margin: 20px 0;
}

.DashboardPage-host.status-ok .DashboardPage-host-details {
    border-left-color: #333;
}

.DashboardPage-host.status-warning .DashboardPage-host-details {
    border-left-color: #333;
}

.DashboardPage-host.status-critical .DashboardPage-host-details {
    border-left-color: #333;
}

.DashboardPage-host-name {
    font-weight: bold;
    flex-grow: 0;
    flex-shrink: 0;
}

.DashboardPage-host-tags {
    flex-grow: 1;
    flex-shrink: 1;
}

.DashboardPage-host-message {
    margin-top: 5px;
    flex-grow: 0;
    flex-shrink: 0;
}

@media (max-width: 1000px) {
    .DashboardPage-hosts {
        grid-template-columns: 50% 50%;
    }
}

@media (max-width: 600px) {
    .DashboardPage-hosts {
        grid-template-columns: 100%;
    }
}
