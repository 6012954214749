.Button {
    background: #b6b63f;
    color: #fff;
    border: none;
    border-radius: 10px;
    letter-spacing: 1px;
    padding: 15px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color ease 0.3s, border-color ease 0.3s, color ease 0.3s;
    text-decoration: none;
    display: inline-block;
    box-sizing: border-box;
    font-family: 'Muli', sans-serif;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-right: 20px;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.1);
}

.Button.disabled {
    color: #555;
    border-color: #555;
    cursor: not-allowed;
}

.Button:not(.disabled):hover,
.Button:not(.disabled).active {
    background: #96961f;
    color: #fff;
    border-color: #336068;
}

.Button svg {
    padding-right: 10px;
}
